<template>
    <div id="customisation-navigation-page">
        <toolbar-back-button-component :title="$t('custom_navigation_page.title')" />

        <p class="font-14 ma-4 text-align-justify font-color-medium">{{ $t('custom_navigation_page.description') }}</p>

        <drop-list :items="navbar_configurations" @insert="insert" @reorder="$event.apply(navbar_configurations)">
            <template v-slot:item="{ item }">
                <drag
                    :key="item.page_name"
                    :data="item"
                    @cut="remove(navbar_configurations, item)"
                    @dragend="updateOrCreateUserNavbarConfigurations()"
                >
                    <v-row
                        v-if="userHasAccess(item.designation, role_no_access)"
                        :class="item.displayed ? 'background-white' : 'background-lightgray dnd-no-drag'"
                        class="ma-4 mt-0"
                    >
                        <v-col cols="1">
                            <v-icon color="black">mdi-equal</v-icon>
                        </v-col>
                        <v-col class="dnd-no-drag display-flex-align-center" cols="11">
                            <v-icon class="pr-2">{{ item.icon }}</v-icon>
                            <span v-if="item.page_link === 'equipment'">
                                {{ page_name_equipment }}
                            </span>
                            <span v-else> {{ $t('configuration_navbar.' + item.page_name) }}</span>
                        </v-col>
                    </v-row>
                </drag>
            </template>
        </drop-list>
    </div>
</template>

<script>
import { Drag, DropList } from 'vue-easy-dnd'
import ToolbarBackButtonComponent from '@/components/Global/ToolbarBackButtonComponent'

export default {
    name: 'CustomisationNavigationPage',
    components: {
        ToolbarBackButtonComponent,
        Drag,
        DropList,
    },
    data() {
        return {
            navbar_configurations: this.$store.getters['user_navbar_configuration/navbar_configurations'],
        }
    },
    methods: {
        insert(event) {
            this.items.splice(event.index, 0, event.data)
        },

        remove(array, value) {
            let index = array.indexOf(value)
            array.splice(index, 1)
        },

        updateOrCreateUserNavbarConfigurations() {
            if (this.$store.getters['user_navbar_configuration/user_navbar_configurations'].id_user) {
                this.$store
                    .dispatch('user_navbar_configuration/updateUserNavbarConfigurations', {
                        navbar_configuration: this.navbar_configurations,
                    })
                    .catch((error) => {
                        this.manageError(error)
                    })
            } else {
                this.$store
                    .dispatch('user_navbar_configuration/createUserNavbarConfigurations', {
                        navbar_configuration: this.navbar_configurations,
                    })
                    .catch((error) => {
                        this.manageError(error)
                    })
            }
        },
    },
}
</script>

<style>
.drop-allowed.drop-in * {
    cursor: inherit !important;
}
</style>
